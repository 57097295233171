import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import axios from "axios";
import VueAxios from "vue-axios";
import VueFeather from "vue-feather";

const app = createApp(App);

app.config.globalProperties.$fingerprint = FingerprintJS;
app.use(store);
app.use(router);
app.component("vue-feather", VueFeather);
app.use(VueAxios, axios);
app.mount("#app");


import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      nowFocused: 1,
      value: [],
      code: "",
    };
  },
  watch: {
    clear(clear) {
      if (clear) {
        this.value = [];
      }
    },
    value: {
      handler() {
        this.selectNextInput(`code-${this.nowFocused + 1}`);
        this.$emit("value", this.value);
      },
      deep: true,
    },
  },
  methods: {
    pasteText(event: any, item: number) {
      let pasteData = event.clipboardData.getData("text");
      if (pasteData.length > 0) {
        pasteData = pasteData.replace(/[^\d]+/g, "");
        if (pasteData.length === this.length) {
          this.value = [];
          this.value = pasteData.split("");
        } else {
          pasteData = pasteData.slice(0, this.length);
          pasteData = pasteData.split("");
          let inputInit = item;
          pasteData.forEach((element: string) => {
            this.value[inputInit] = element;
            inputInit = inputInit + 1;
          });
        }
      }
    },
    focused(nowFocused: number) {
      if (nowFocused === this.length) {
        this.nowFocused = 0;
      } else {
        this.nowFocused = nowFocused;
      }
    },
    selectNextInput(ref: string) {
      let input = this.$refs[ref];
      if (input) {
        input.focus();
        input.select();
      }
    },
  },
})
export default class Code extends Vue {}

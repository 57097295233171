import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-040fec19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = ["onUpdate:modelValue", "onFocus", "onPaste"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.length, (item) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        maxlength: "1",
        class: "input mr-1 ml-1 text-center",
        key: item - 1,
        type: "number",
        "onUpdate:modelValue": ($event: any) => ((_ctx.value[item - 1]) = $event),
        onFocus: ($event: any) => (_ctx.focused(item - 1)),
        onPaste: _withModifiers(($event: any) => (_ctx.pasteText($event, item - 1)), ["prevent"]),
        ref: `code-${item - 1}`
      }, null, 40, _hoisted_2)), [
        [_vModelText, _ctx.value[item - 1]]
      ])
    }), 128))
  ]))
}
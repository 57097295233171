<template>
  <div class="box-input flex flex-col">
    <input
      v-model="data"
      class="input-custom pb-2"
      :name="name"
      :placeholder="placeholder"
    />
    <div class="input-loader flex bg-gray-200">
      <span
        :style="{ width: `${!error ? barLoaderProgress : 100}%` }"
        class="bg-dpc loader-anim"
        :class="!error ? 'bg-dpc' : 'bg-red-600'"
      ></span>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    timeforloading: {
      type: Number,
      default: 100,
    },
    name: {
      type: String,
      default: "Input",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Digite...",
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data(data) {
      this.$emit("update:data", data); // previously was `this.$emit('input', title)`
    },
    loading(loading) {
      if (loading) {
        this.barLoader(this.timeforloading);
      }
    },
  },
  emits: ["update:data", "finishloader"],
  data() {
    return {
      data: this.value || "",
      barLoaderProgress: 0,
    };
  },
  methods: {
    async barLoader(time = 20) {
      const barProgressTimer = time / 100;
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      this.barLoaderProgress = 0;
      while (this.barLoaderProgress < 100) {
        this.barLoaderProgress += barProgressTimer;
        await timer(barProgressTimer);
      }
      this.$emit("finishloader", true);
    },
  },
})
export default class Input extends Vue {}
</script>
<style>
.input-custom {
  width: 100%;
  outline: none;
}
.input-loader {
  width: 100%;
  height: 3px;
  position: relative;
}
.loader-anim {
  transition: all ease-in;
  position: absolute;
  height: 100%;
}
</style>


import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/index.vue";
import Spin from "@/components/spin/index.vue";
import Code from "@/components/code/index.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

@Options({
  components: { Modal, Spin, Code },
  props: {
    width: {
      type: String,
      default: "40%",
    },
    height: {
      type: String,
      default: "90%",
    },
    idusuario: {
      type: Number,
      default: null,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    deviceFingerPrint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      password: "",
      isLoading: false,
      errorCodeMessage: null,
      formWizardSteps: {
        stepOne: true,
        stepTwo: false,
        stepTree: false,
        stepFour: false,
      },
      confirmPassword: "",
      errorMessage: null,
    };
  },
  computed: {
    validationLength() {
      return this.password.length >= 8;
    },
    validationMaiuscula() {
      return /[A-Z]/.test(this.password);
    },
    validationMinuscula() {
      return /[a-z]/.test(this.password);
    },
    validationNumerico() {
      return /[0-9]/.test(this.password);
    },
    validationEspecial() {
      return /\W|_/.test(this.password);
    },
    validationMatch() {
      return this.password === this.confirmPassword;
    },
    errors() {
      return !(
        this.validationLength &&
        this.validationMaiuscula &&
        this.validationMinuscula &&
        this.validationNumerico &&
        this.validationMatch &&
        this.validationNumerico
      );
    },
  },
  methods: {
    goToNextStep(afterStep: number, step: number) {
      switch (afterStep) {
        case 1:
          this.formWizardSteps.stepOne = false;
          break;
        case 2:
          this.formWizardSteps.stepTwo = false;
          break;
        case 3:
          this.formWizardSteps.stepTree = false;

          break;
        case 4:
          this.formWizardSteps.stepFour = false;
          break;
        default:
          break;
      }
      switch (step) {
        case 1:
          this.formWizardSteps.stepOne = true;
          break;
        case 2:
          this.formWizardSteps.stepTwo = true;
          break;
        case 3:
          this.formWizardSteps.stepTree = true;

          break;
        case 4:
          this.formWizardSteps.stepFour = true;
          break;
        default:
          break;
      }
    },
    requirimentsIcon(success: boolean, type: string) {
      if (type == "icon") {
        if (!success) {
          return "x";
        }
        return "check";
      }
      if (type == "text") {
        if (!success) {
          return "text-red-500";
        }
        return "text-green-500";
      }
    },
    onSubmit(e: Event) {
      e.preventDefault();
    },
    verifyCode(code: Array<string>) {
      this.code = code;
      this.code = this.code.join("");
      if (this.code.length === 7) {
        this.checkVerifyCode();
      }
    },
    async checkVerifyCode() {
      this.isLoading = true;
      try {
        const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_PASSWORD_RESET_CODE}/${this.code}/${this.idusuario}`;
        const { data } = await this.axios.get(url);
        if (!data.error) {
          this.goToNextStep(2, 3);
          this.errorCodeMessage = null;
        } else {
          this.errorCodeMessage = data.message;
        }
      } catch (error) {
        createToast(
          { title: "Error", description: error.message },
          {
            toastBackgroundColor: "#f43f5e",
            showIcon: true,
            timeout: 4000,
            type: "danger",
          }
        );
      }

      this.isLoading = false;
    },
    async changePassword() {
      if (this.errors) {
        this.errorMessage =
          "O formulário abauixo possui erros, verifique se sua senha bate com todos os critérios de senha forte ou se as senhas digitadas são iguais.";
      } else {
        this.isLoading = true;
        this.errorMessage = null;
        try {
          const payload = {
            cod_usuario: this.idusuario,
            senha: this.password,
            confirmaSenha: this.confirmPassword,
            deviceFingerPrint: this.deviceFingerPrint,
            code: this.code,
          };
          const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_PASSWORD_RESET}`;
          const { data } = await this.axios.post(url, payload);
          // Valida se aconteceu algum erro
          if (!data.error) {
            this.goToNextStep(3, 4);
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            setTimeout(function () {
              that.$emit("sucess", {
                message: data.mensagem,
                newPassword: that.password,
              });
            }, 1300);
          } else {
            this.errorMessage = data.mensagem;
            throw { message: data.mensagem };
          }
        } catch (error) {
          createToast(
            { title: "Error", description: error.message },
            {
              toastBackgroundColor: "#f43f5e",
              showIcon: true,
              timeout: 4000,
              type: "danger",
            }
          );
        }
        this.isLoading = false;
      }
    },
  },
})
export default class ModalPassword extends Vue {}

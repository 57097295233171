<template>
  <div class="modal">
    <div class="modal-content">
      <div class="box" :style="{ width: width, height: height }">
        <header>
          <slot name="header">
            <div class="flex w-full justify-end pr-4 pt-3">
              <span @click="$emit('close')" class="p-4 cursor-pointer"
                ><i class="fas fa-times"></i>
                <vue-feather
                  stroke-width="4"
                  class="text-red-500 hover:text-red-700"
                  size="16"
                  type="x"
                ></vue-feather>
              </span>
            </div>
          </slot>
        </header>
        <main class="h-4/5">
          <slot></slot>
        </main>
        <footer>
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    width: {
      type: String,
      default: "40%",
    },
    height: {
      type: String,
      default: "90%",
    },
  },
})
export default class Modal extends Vue {}
</script>

<style scoped>
.box {
  background-color: #fff;
  border-radius: 4px;
}
.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.24);
  display: table;
  transition: all 0.5s ease;
  overflow-y: scroll;
  align-items: flex-end;
}
.modal-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

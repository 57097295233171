
import { Options, Vue } from "vue-class-component";
import Input from "@/components/input/index.vue";
import ChangePassword from "@/components/modal/changePassword.vue";
import Modal from "@/components/modal/index.vue";
import Code from "@/components/code/index.vue";
import Spin from "@/components/spin/index.vue";
import * as Account from "@/services/Account";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

import VueRecaptcha from "@/components/VueRecaptcha";

@Options({
  data() {
    return {
      isLoading: false,
      modalCodeActive: false,
      username: "",
      passwordIsExpired: false,
      password: "",
      windowWidth: 1366,
      verifyChallengeError: false,
      windowHeight: 768,
      usernameError: false,
      passwordErrorMessage: null,
      usernameErrorMessage: null,
      clearInput: false,
      modal: {
        width: "35%",
        height: "90%",
      },
      codeInvalid: {
        error: false,
        message: null,
      },
      passwordError: false,
      deviceFingerPrint: null,
      enableCaptcha: false,
      captchaSucess: null,
      showCaptcha: false,
      modalChangePassword: {
        active: false,
        idusuario: null,
        deviceFingerPrint: null,
      },
      lastUsername: "",
      unknownDevice: {
        idusuario: null,
        active: false,
        error: null,
        hiddenEmail: null,
      },
      code: null,
      recaptchaKey: "6Lfwg2gbAAAAANyioD4dazf2Egkjs8fZyfqMWO4A",
    };
  },
  components: {
    Input,
    ChangePassword,
    Modal,
    Code,
    Spin,
    "vue-recaptcha": VueRecaptcha,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  created() {
    const fpPromise = this.$fingerprint.load();
    fpPromise
      .then((fp: { get: () => unknown }) => fp.get())
      .then((result: { visitorId: string }) => {
        // This is the visitor identifier:
        this.deviceFingerPrint = result.visitorId;
      });
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth <= 2000 && newWidth >= 1500) {
        this.modal.width = "30%";
        this.modal.height = "70%";
      } else if (newWidth <= 1500 && newWidth >= 1000) {
        this.modal.width = "40%";
        this.modal.height = "90%";
      } else if (newWidth <= 1000 && newWidth >= 650) {
        this.modal.width = "80%";
      } else if (newWidth <= 650) {
        this.modal.width = "100%";
      } else {
        this.modal.width = "35%";
        this.modal.height = "90%";
      }
    },
    username(username: string) {
      if (username !== this.lastUsername) {
        if (this.captchaSucess || !this.showCaptcha)
          this.validateUser(username);
        this.lastUsername = username;
      }
    },
    deviceFingerPrint(value: string) {
      if (value.length) this.checkDeviceClearedAcessLimit();
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    changePasswordSucess(data: { newPassword: string; message: string }) {
      if (data.newPassword) {
        this.modalChangePassword.active = false;
        this.password = data.newPassword;
        this.login();
      }
    },
    onSubmit(e: Event) {
      e.preventDefault();
    },
    verifyCode(code: any) {
      this.code = code;
      this.code = this.code.join("");
      if (this.code.length === 7 && this.unknownDevice.idusuario) {
        this.checkVerifyCode();
      }
    },
    async checkVerifyCode() {
      this.isLoading = true;
      try {
        const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_AUTH_CODE}`;
        const payload = {
          idusuario: this.unknownDevice.idusuario,
          code: this.code,
          deviceFingerPrint: this.deviceFingerPrint,
        };
        const { data } = await this.axios.post(url, payload);
        if (!data.error) {
          this.unknownDevice = {
            idusuario: null,
            active: false,
            error: null,
            hiddenEmail: null,
          };
          this.clearInput = true;
          this.codeInvalid.message = null;
          this.codeInvalid.error = false;
          this.login();
        } else {
          this.codeInvalid.error = true;
          this.clearInput = true;
          this.codeInvalid.message = data.message;
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const that = this;
          setTimeout(function () {
            that.unknownDevice = {
              idusuario: null,
              active: false,
              error: null,
              hiddenEmail: null,
            };
            that.codeInvalid.error = false;
            that.clearInput = false;
            that.clearInput = false;
            that.codeInvalid.message = null;
          }, 5000);
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async login() {
      if (
        (this.showCaptcha && this.captchaSucess) ||
        this.showCaptcha === false
      ) {
        if (this.password.length && this.username.length) {
          this.isLoading = true;
          this.passwordIsExpired = false;
          try {
            const payload = {
              username: this.username,
              password: this.password,
              deviceFingerPrint: this.deviceFingerPrint,
            };
            const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_AUTH_LOGIN}`;
            const { data } = await this.axios.post(url, payload);
            if (data.error && data.errorCode) {
              switch (data.errorCode) {
                case 1001:
                  this.showCaptcha = data.enableCaptcha || false;
                  this.usernameError = true;
                  this.usernameErrorMessage = "Usuário não existe";
                  break;

                case 1002:
                  this.showCaptcha = data.enableCaptcha || false;
                  this.passwordError = true;
                  this.passwordErrorMessage = data.message;
                  throw { message: data.message };

                case 1003:
                  this.unknownDevice.active = true;
                  this.unknownDevice.error = data.message;
                  this.unknownDevice.hiddenEmail = data.hiddenEmail;
                  this.unknownDevice.idusuario = data.userId;
                  break;

                case 1005:
                  this.modalChangePassword.active = true;
                  this.modalChangePassword.idusuario = data.userId;
                  this.modalChangePassword.deviceFingerPrint =
                    this.deviceFingerPrint;
                  break;
                case 1006:
                  this.modalChangePassword.active = true;
                  this.modalChangePassword.idusuario = data.userId;
                  this.modalChangePassword.deviceFingerPrint =
                    this.deviceFingerPrint;
                  this.passwordIsExpired = true;
                  break;

                case 1007:
                  createToast(
                    { title: "Atenção", description: data.message },
                    {
                      toastBackgroundColor: "#ffb703",
                      showIcon: true,
                      timeout: 60000,
                      type: "warning",
                    }
                  );
                  break;

                case 1008:
                  createToast(
                    { title: "Erro", description: data.message },
                    {
                      toastBackgroundColor: "#f43f5e",
                      showIcon: true,
                      timeout: 10000,
                      type: "danger",
                    }
                  );
                  break;

                default:
                  break;
              }
            } else if (data.token) {
              Account.login(data.token);
              this.passwordError = false;
              let rd = this.$route.query.rd;
              if (rd) {
                window.location.href = rd;
              } else {
                this.$router.go(-1);
              }
            }
          } catch (error) {
            createToast(
              { title: "Erro", description: error.message },
              {
                toastBackgroundColor: "#f43f5e",
                showIcon: true,
                timeout: 4000,
                type: "danger",
              }
            );
          }
        }
        this.isLoading = false;
      } else {
        createToast(
          { title: "Error", description: "Por favor verifique o captcha" },
          {
            toastBackgroundColor: "#ffb703",
            showIcon: true,
            timeout: 4000,
            type: "warning",
          }
        );
      }
    },
    async onVerifyReCaptcha(token: string, origin: number) {
      try {
        const payload = { recaptchaToken: token, origin: origin };
        const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_RECAPTCHA_VERIFY}`;
        const { data } = await this.axios.post(url, payload);
        if (data.captcha.success) {
          this.captchaSucess = data.captcha.success;
        } else {
          throw { message: "Ocorreu um erro ao verificar o captcha" };
        }
      } catch (error) {
        createToast(
          { title: "Error", description: error.message },
          {
            toastBackgroundColor: "#f43f5e",
            showIcon: true,
            timeout: 4000,
            type: "danger",
          }
        );
      }
    },
    async checkDeviceClearedAcessLimit() {
      const url = `${process.env.VUE_APP_API_AUTH_HOST}${process.env.VUE_APP_DEVICE_VERIFY}`;
      const payload = { deviceFingerPrint: this.deviceFingerPrint };
      const { data } = await this.axios.post(url, payload);
      if (data.enableCaptcha) {
        this.showCaptcha = true;
      }
    },
    async validateUser(username: string) {
      try {
        const payload = { username: username };
        const url =
          process.env.VUE_APP_API_AUTH_HOST + process.env.VUE_APP_AUTH_USERNAME;
        const { data } = await this.axios.post(url, payload);
        if (!data.error) {
          this.usernameErrorMessage = null;
          this.usernameError = false;
        } else {
          throw data.message;
        }
      } catch (error) {
        this.usernameError = true;
        this.usernameErrorMessage = "Usuário não existe.";
      }
      this.loadingUsername = false;
    },
  },
})
export default class Login extends Vue {}
